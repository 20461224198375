<template>
    <div class="table-box">
        <!-- <div class="step_box">
            <div class="step" v-for="(item, index) in tableData" :key="index">
                <div class="step_con" @click.prevent="onHandleDetails(item)">
                    <p>
                        {{ returnText(item) }}
                    </p>
                    <span style="width: 120px">金额 {{ item.amount }}元</span>
                    <span style="width: 200px"
                        >申请时间 {{ item.applyTime }}</span
                    >
                    <el-button
                        type="primary"
                        style="background: #f5a623; border-color: #f5a623"
                    >
                        {{ returnResult(item) }}
                    </el-button>
                </div>
            </div>
        </div> -->
        <el-table
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :key="1"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '600',
            }"
        >
            <el-table-column label="时间" show-overflow-tooltip width="160">
                <template slot-scope="scope">
                    <span>{{
                        scope.row.applyTime ? scope.row.applyTime : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="10"> </el-table-column>
            <el-table-column label="编号" show-overflow-tooltip width="150">
                <template slot-scope="scope">
                    <span>{{ scope.row.numberNo || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="10"> </el-table-column>
            <el-table-column label="部门" show-overflow-tooltip width="93">
                <template slot-scope="scope">
                    <span>{{ scope.row.departmentName || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="10"> </el-table-column>
            <el-table-column label="申请人" show-overflow-tooltip width="93">
                <template slot-scope="scope">
                    <span>{{
                        scope.row.adminName ? scope.row.adminName : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="10"> </el-table-column>
            <el-table-column label="名称" show-overflow-tooltip width="200">
                <template slot-scope="scope">
                    <span>{{ scope.row.customerName || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="10"> </el-table-column>
            <el-table-column label="事项" width="150">
                <template slot-scope="scope">
                    <el-button
                        style="color: #2370eb"
                        @click="onHandleDetails(scope.row)"
                        type="text"
                    >
                        {{ returnText(scope.row) }}
                    </el-button>
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
    </div>
</template>
<script>
import { approvalList } from '@/api/workbench/audit.js';
export default {
    components: {},
    data() {
        return {
            tableData: [],
            btnP: {},
            userType: sessionStorage.getItem('userType'),
            auditStatus: '',
        };
    },
    methods: {
        // 获取列表
        getData(searchVal, pageNum, pageSize, btnP) {
            let data = {
                param: {
                    adminId: sessionStorage.getItem('adminId'),
                },
                pageNum: pageNum,
                pageSize: pageSize,
            };
            if (searchVal.name) {
                data.param.name = searchVal.name;
            }
            this.auditStatus = searchVal.auditStatus;
            if (!searchVal.auditStatus) {
                data.param.typeList = [1, 2, 3, 4];
                data.param.invoiceStatusList = [1, 2, 3, 4,5];
                data.param.rechargeStatusList = [1, 2, 3, 4, 5];
                data.param.declareStatusList = [1, 2, 4, 5, 6, 7, 11, 12];
                data.param.expenseStatusList = [2, 4, 7, 11];
                // 发票
                if (searchVal.type[0] == 2 && searchVal.type[1] == 1) {
                    data.param.typeList = [1];
                    data.param.invoiceStatusList = [1, 2, 3, 4,5];
                    delete data.param.rechargeStatusList;
                    delete data.param.declareStatusList;
                    delete data.param.expenseStatusList;
                }
                // 充值
                if (searchVal.type[0] == 3 && searchVal.type[1] == 1) {
                    data.param.typeList = [2];
                    delete data.param.invoiceStatusList;
                    data.param.rechargeStatusList = [1, 2, 3, 4, 5];
                    delete data.param.declareStatusList;
                    delete data.param.expenseStatusList;
                }
                // 申报
                if (searchVal.type[0] == 3 && searchVal.type[1] == 2) {
                    data.param.typeList = [3];
                    delete data.param.invoiceStatusList;
                    delete data.param.rechargeStatusList;
                    data.param.declareStatusList = [1, 2, 4, 5, 6, 7, 11, 12];
                    delete data.param.expenseStatusList;
                }
                // 报销
                if (searchVal.type[0] == 3 && searchVal.type[1] == 3) {
                    data.param.typeList = [4];
                    delete data.param.invoiceStatusList;
                    delete data.param.rechargeStatusList;
                    delete data.param.declareStatusList;
                    data.param.expenseStatusList = [2, 4, 7, 11];
                }
            }
            if (searchVal.auditStatus == '待审批') {
                data.param.typeList = [1, 2, 3, 4];
                data.param.invoiceStatusList = [1];
                data.param.rechargeStatusList = [1, 2];
                data.param.declareStatusList = [1, 2];
                data.param.expenseStatusList = [2, 4];
                // 发票
                if (searchVal.type[0] == 2 && searchVal.type[1] == 1) {
                    data.param.typeList = [1];
                    data.param.invoiceStatusList = [1];
                    delete data.param.rechargeStatusList;
                    delete data.param.declareStatusList;
                    delete data.param.expenseStatusList;
                }
                // 充值
                if (searchVal.type[0] == 3 && searchVal.type[1] == 1) {
                    data.param.typeList = [2];
                    delete data.param.invoiceStatusList;
                    data.param.rechargeStatusList = [1, 2];
                    delete data.param.declareStatusList;
                    delete data.param.expenseStatusList;
                }
                // 申报
                if (searchVal.type[0] == 3 && searchVal.type[1] == 2) {
                    data.param.typeList = [3];
                    delete data.param.invoiceStatusList;
                    delete data.param.rechargeStatusList;
                    data.param.declareStatusList = [1, 2];
                    delete data.param.expenseStatusList;
                }
                // 报销
                if (searchVal.type[0] == 3 && searchVal.type[1] == 3) {
                    data.param.typeList = [4];
                    delete data.param.invoiceStatusList;
                    delete data.param.rechargeStatusList;
                    delete data.param.declareStatusList;
                    data.param.expenseStatusList = [2, 4];
                }
            }
            if (searchVal.auditStatus == '已通过') {
                data.param.typeList = [1, 2, 3, 4];
                data.param.invoiceStatusList = [2,5];
                data.param.rechargeStatusList = [4];
                data.param.declareStatusList = [4, 5, 11];
                data.param.expenseStatusList = [11];
                // 发票
                if (searchVal.type[0] == 2 && searchVal.type[1] == 1) {
                    data.param.typeList = [1];
                    data.param.invoiceStatusList = [2,5];
                    delete data.param.rechargeStatusList;
                    delete data.param.declareStatusList;
                    delete data.param.expenseStatusList;
                }
                // 充值
                if (searchVal.type[0] == 3 && searchVal.type[1] == 1) {
                    data.param.typeList = [2];
                    delete data.param.invoiceStatusList;
                    data.param.rechargeStatusList = [4];
                    delete data.param.declareStatusList;
                    delete data.param.expenseStatusList;
                }
                // 申报
                if (searchVal.type[0] == 3 && searchVal.type[1] == 2) {
                    data.param.typeList = [3];
                    delete data.param.invoiceStatusList;
                    delete data.param.rechargeStatusList;
                    data.param.declareStatusList = [4, 5, 11];
                    delete data.param.expenseStatusList;
                }
                // 报销
                if (searchVal.type[0] == 3 && searchVal.type[1] == 3) {
                    data.param.typeList = [4];
                    delete data.param.invoiceStatusList;
                    delete data.param.rechargeStatusList;
                    delete data.param.declareStatusList;
                    data.param.expenseStatusList = [11];
                }
            }
            if (searchVal.auditStatus == '已驳回') {
                data.param.typeList = [1, 2, 3, 4];
                data.param.invoiceStatusList = [3];
                data.param.rechargeStatusList = [3];
                data.param.declareStatusList = [6, 7, 12];
                data.param.expenseStatusList = [7];
                // 发票
                if (searchVal.type[0] == 2 && searchVal.type[1] == 1) {
                    data.param.typeList = [1];
                    data.param.invoiceStatusList = [3];
                    delete data.param.rechargeStatusList;
                    delete data.param.declareStatusList;
                    delete data.param.expenseStatusList;
                }
                // 充值
                if (searchVal.type[0] == 3 && searchVal.type[1] == 1) {
                    data.param.typeList = [2];
                    delete data.param.invoiceStatusList;
                    data.param.rechargeStatusList = [3];
                    delete data.param.declareStatusList;
                    delete data.param.expenseStatusList;
                }
                // 申报
                if (searchVal.type[0] == 3 && searchVal.type[1] == 2) {
                    data.param.typeList = [3];
                    delete data.param.invoiceStatusList;
                    delete data.param.rechargeStatusList;
                    data.param.declareStatusList = [6, 7, 12];
                    delete data.param.expenseStatusList;
                }
                // 报销
                if (searchVal.type[0] == 3 && searchVal.type[1] == 3) {
                    data.param.typeList = [4];
                    delete data.param.invoiceStatusList;
                    delete data.param.rechargeStatusList;
                    delete data.param.declareStatusList;
                    data.param.expenseStatusList = [7];
                }
            }
            if (searchVal.auditStatus == '已撤销') {
                data.param.typeList = [1, 2, 3];
                data.param.invoiceStatusList = [4];
                data.param.rechargeStatusList = [5];
                data.param.declareStatusList = [9];

                // 发票
                if (searchVal.type[0] == 2 && searchVal.type[1] == 1) {
                    data.param.typeList = [1];
                    data.param.invoiceStatusList = [4];
                    delete data.param.rechargeStatusList;
                }
                // 充值
                if (searchVal.type[0] == 3 && searchVal.type[1] == 1) {
                    data.param.typeList = [2];
                    delete data.param.invoiceStatusList;
                    data.param.rechargeStatusList = [5];
                }
                // 申报
                if (searchVal.type[0] == 3 && searchVal.type[1] == 2) {
                    data.param.typeList = [3];
                    delete data.param.invoiceStatusList;
                    delete data.param.rechargeStatusList;
                    data.param.declareStatusList = [9];
                }
                // 报销
                if (searchVal.type[0] == 3 && searchVal.type[1] == 3) {
                    this.tableData = [];
                    this.$emit('pageData', 0);
                    return;
                }
            }
            this.pageData = {
                searchVal,
                pageNum,
                pageSize,
            };
            approvalList(data).then((res) => {
                if (res.code == 200) {
                    this.tableData = res.data.list;
                    this.$emit('pageData', res.data.total);
                }
            });
        },
        onHandleDetails(row) {
            if (row.type == 1) {
                this.onInvoiceDetails(row);
            }
            if (row.type == 2) {
                this.onRechargeDetails(row);
            }
            if (row.type == 3) {
                this.onDeclareDetails(row);
                sessionStorage.setItem(
                    'pageData',
                    JSON.stringify(this.pageData)
                );
            }
            if (row.type == 4) {
                this.onReimbDetails(row);
            }
        },

        onInvoiceDetails(row) {
            this.$emit('onInvoiceDetails', row);
        },

        onDeclareDetails(row) {
            this.$emit('onDeclareDetails', row);
        },

        onReimbDetails(row) {
            this.$emit('onReimbDetails', row);
        },
        onRechargeDetails(row) {
            this.$emit('onRechargeDetails', row);
        },
        returnText(item) {
            if (item.type == 1) {
                return '发票';
            }
            if (item.type == 2) {
                return '充值';
            }
            if (item.type == 3) {
                if (item.status == 4 && item.payWay == 2) {
                    return '申报支付确认';
                }
                return '销售费用申报';
            }
            if (item.type == 4) {
                if (item.status == 4) {
                    return '报销支付确认';
                }
                return '报销';
            }
            if (item.type == 5) {
                return '报备延期';
            }
        },
        // returnResult(item) {
        //     if (item.type == 1) {
        //         return item.status == 1
        //             ? '待审批'
        //             : item.status == 2
        //             ? '已通过'
        //             : item.status == 3
        //             ? '已驳回'
        //             : item.status == 4
        //             ? '已撤销'
        //             : item.status == 5
        //             ? '已开票'
        //             : item.status == 6
        //             ? '已作废'
        //             : '- -';
        //     }
        //     if (item.type == 2) {
        //         return this.$tableDataHandle.rechargeStatus(item.status);
        //     }
        //     if (item.type == 3) {
        //         return this.$cost.costStatus(item.status);
        //     }
        //     if (item.type == 4) {
        //         return this.$cost.reimbStatus(item.status);
        //     }
        //     if (item.type == 5) {
        //         return this.$MailStatus.reportStatus(item.status);
        //     }
        // },
        returnResult(item) {
            if (item.type == 1) {
                switch (item.status) {
                    case 1:
                        return '待审批';
                    case 2:
                        return '已通过';
                    case 3:
                        return '已驳回';
                    case 4:
                        return '已撤销';

                    default:
                        return '- -';
                }
            }
            if (item.type == 2) {
                if (item.status == 1 || item.status == 2) {
                    return '待审批';
                }
                if (item.status == 4) {
                    return '已通过';
                }
                if (item.status == 3) {
                    return '已驳回';
                }
                if (item.status == 5) {
                    return '已撤销';
                }
                return '- -';
            }
            if (item.type == 3) {
                if (item.status == 1 || item.status == 2) {
                    return '待审批';
                }
                if (item.status == 5 || item.status == 11 || item.status == 4) {
                    return '已通过';
                }
                if (item.status == 6 || item.status == 7 || item.status == 12) {
                    return '已驳回';
                }
                if (item.status == 9) {
                    return '已撤销';
                }
                return '- -';
            }
            if (item.type == 4) {
                if (item.status == 2 || item.status == 4) {
                    return '待审批';
                }
                if (item.status == 11) {
                    return '已通过';
                }
                if (item.status == 7) {
                    return '已驳回';
                }
                return '- -';
            }
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}

.icon-dianhua,
.icon-weixin,
.icon-QQ,
.icon-diannao,
.icon-youxiang,
.icon-youxiang1,
.icon-duanxinxiaoxixinxi,
.icon-qita,
.icon-yuyuebaifang,
.icon-qita1 {
    position: static;
    left: -10px !important;
}
.table-box {
    width: 100%;
    height: 100%;
    background-color: #fff;
    // /deep/ .el-table tr td {
    //     padding: 5px 0;
    // }
    .info {
        display: flex;
        width: 80%;
        margin: 0 auto;
        color: #333;
        line-height: 32px;
        label {
            width: 90px;
            font-weight: 600;
        }
        p {
            flex: 1;
        }
    }
    .step_box {
        padding: 22px 100px 22px 50px;
        .step {
            position: relative;
            font-size: 14px;

            border-radius: 4px;
            padding-bottom: 32px;
            .step_con {
                display: flex;
                padding: 20px 35px 18px 20px;
                color: #333;
                position: relative;
                background: #f8f9fa;
                font-size: 14px;
                justify-content: space-between;
                p {
                    font-weight: 600;
                    width: 380px;
                    color: #2370eb;
                    cursor: pointer;
                }
                button {
                    background: #2370eb;
                    border-radius: 2px;
                    border-color: #2370eb;
                    width: 80px;
                    height: 31px;
                    margin-top: -5px;
                }
            }
            .pay_confirm {
                > div {
                    display: flex;
                    flex-direction: column;
                    span {
                        margin-top: 8px;
                    }
                }
            }
            .step_con:before {
                content: '';
                position: absolute;
                left: -22px;
                top: 24px;

                width: 8px;
                height: 8px;
                border-radius: 50%;
                border: 2px solid rgba($color: #2370eb, $alpha: 0.3);
            }
        }
        .step:before {
            content: '';
            position: absolute;
            left: -20px;
            top: 26px;

            width: 8px;
            height: 8px;
            border-radius: 50%;
            z-index: 2;
            background-color: #2370eb;
        }
        .step:after {
            content: '';
            position: absolute;
            left: -17px;
            top: 31px;
            z-index: 1;
            height: 100%;
            border-left: 1px dashed #e9f2ff;
        }
        .step:last-child:after {
            content: '';

            border-left: none;
        }
    }
}
.dl_con {
    width: 80%;
    margin: 35px auto 100px;
    text-align: center;
    color: #333;
    font-weight: 600;
    font-size: 14px;
    i {
        font-weight: 400;
        font-size: 60px;
        color: #f5a623;
        display: block;
        margin-bottom: 20px;
    }
}
.copy {
    height: 20px;
    background: #fff1d9;
    border-radius: 20px;
    line-height: 20px;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
    display: inline-block;
    padding: 0 8px 0 25px;
    color: #ff9c39;
    i {
        position: absolute;
        left: -10px;
        top: -10px;
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #ff9c39;
        color: #fff;
        text-align: center;
        line-height: 40px;
        font-size: 20px;
        transform: scale(0.5);
    }
}
.copy2 {
    height: 20px;
    background: #fff1d9;
    border-radius: 20px;
    line-height: 20px;

    font-size: 12px;
    display: inline-block;
    padding: 0 8px 0 25px;
    color: #ff9c39;
    position: relative;
    i {
        position: absolute;
        left: -10px;
        top: -10px;
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #ff9c39;
        color: #fff;
        text-align: center;
        line-height: 40px;
        font-size: 20px;
        transform: scale(0.5);
    }
}
.way_box_fs {
    line-height: 30px;
    em {
        display: inline-block;
        width: 30px;
        font-style: normal;
    }
    .contactContent {
        width: 120px !important;
        display: inline-block;
    }
}
</style>
<style>
.el-checkbox__input.is-disabled .el-checkbox__inner {
    display: none;
}
</style>
