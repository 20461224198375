<template>
    <div class="clue_box">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <!-- <span>审批状态</span>
                    <el-radio-group
                        v-model="searchVal.auditStatus"
                        size="small"
                    >
                        <el-radio-button
                            label="待审批"
                            @click.native.prevent="clickitem('待审批')"
                        ></el-radio-button>
                        <el-radio-button
                            label="已通过"
                            @click.native.prevent="clickitem('已通过')"
                        ></el-radio-button>
                        <el-radio-button
                            label="已驳回"
                            @click.native.prevent="clickitem('已驳回')"
                        ></el-radio-button>
                        <el-radio-button
                            label="已撤销"
                            @click.native.prevent="clickitem('已撤销')"
                        ></el-radio-button>
                    </el-radio-group> -->
                    <span>类型</span>
                    <el-cascader
                        v-model="searchVal.type"
                        :options="$searchForm.auditType2()"
                        @change="(pagesize = 20), (currentPage = 1), getData()"
                        size="small"
                        style="width: 205px"
                        placeholder="请选择"
                        :show-all-levels="false"
                    ></el-cascader>
                    <span>查询</span>
                    <el-input
                        size="small"
                        style="width: 200px"
                        placeholder="人名、客户名称、充值名称"
                        v-model.trim="searchVal.name"
                        clearable
                    >
                    </el-input>
                    <el-button
                        @click="(pagesize = 20), (currentPage = 1), getData()"
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                    ></el-button>
                </p>
            </div>
        </div>
        <div class="table_title">
            <div>
                <div class="title_left_btn">
                    <span
                        :class="
                            searchVal.auditStatus == '待审批' ? 'active' : ''
                        "
                        @click="changeActive('待审批')"
                        >待审批</span
                    >

                    <span
                        :class="
                            searchVal.auditStatus == '已通过' ? 'active' : ''
                        "
                        @click="changeActive('已通过')"
                        >已通过</span
                    >
                    <span
                        :class="
                            searchVal.auditStatus == '已驳回' ? 'active' : ''
                        "
                        @click="changeActive('已驳回')"
                        >已驳回</span
                    >
                    <span
                        :class="
                            searchVal.auditStatus == '已撤销' ? 'active' : ''
                        "
                        @click="changeActive('已撤销')"
                        >已撤销</span
                    >
                </div>
            </div>
            <p>
                <button
                    @click="onVoid"
                    class="cancel"
                    v-if="searchVal.auditStatus == '已处理'"
                >
                    <i class="iconfont icon-zuofei" style="font-size: 12px"></i>
                    作废
                </button>
            </p>
        </div>
        <div class="table">
            <Table
                ref="table"
                @pageData="pageData"
                @onInvoiceDetails="onInvoiceDetails"
                @onDeclareDetails="onDeclareDetails"
                @onReimbDetails="onReimbDetails"
                @onRechargeDetails="onRechargeDetails"
            ></Table>
        </div>
        <div class="page-box">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 发票详情 -->
        <el-drawer
            :visible.sync="drawerInvoiceDetails"
            :direction="direction"
            :before-close="handleDrawer"
            size="480px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    发票详情
                </div>
            </template>
            <InvoiceDetails @close="handleDrawer" ref="invoiceDetails" />
        </el-drawer>
        <!-- 申报详情 -->
        <el-drawer
            :visible.sync="drawerDeclareDetails"
            :direction="direction"
            :before-close="handleDrawer"
            size="480px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    申报详情
                </div>
            </template>
            <DeclareDetail @close="handleDrawer" ref="declareDetail" />
        </el-drawer>
        <!-- 报销详情 -->
        <el-drawer
            :visible.sync="drawerReimbDetails"
            :direction="direction"
            :before-close="handleDrawer"
            size="480px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    报销详情
                </div>
            </template>
            <ReimbDetail @close="handleDrawer" ref="reimbDetail" />
        </el-drawer>
        <!-- 详情 -->
        <el-drawer
            :visible.sync="drawerRechargeDetails"
            :direction="direction"
            :before-close="handleDrawer"
            size="477px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    充值历史详情
                </div>
            </template>
            <RechargeDetail
                ref="rechargeDetail"
                @close="handleDrawer"
            ></RechargeDetail>
        </el-drawer>
    </div>
</template>

<script>
import { declareGet } from '@/api/cost/declare.js';
import { expenseGet } from '@/api/reimb/reimb';
import { invoiceGet } from '@/api/contr/invoice.js';
import Table from '../table/table.vue';
import InvoiceDetails from '../../myaudit/invoice/details.vue';
import DeclareDetail from '../../myaudit/declare/details.vue';
import ReimbDetail from '../../myaudit/reimb/details.vue';
import RechargeDetail from '../../../../launch/recharge/detail/detail.vue';
export default {
    components: {
        Table,
        InvoiceDetails,
        DeclareDetail,
        ReimbDetail,
        RechargeDetail,
    },
    data() {
        return {
            searchVal: {
                type: [1],
                auditStatus: '待审批',
            },

            btnP: {},
            pagesize: 20,
            total: 0,
            currentPage: 1,
            direction: 'rtl',
            drawerInvoiceDetails: false,
            drawerDeclareDetails: false,
            drawerReimbDetails: false,
            drawerRechargeDetails: false,
        };
    },

    mounted() {
        this.getBtn();
    },
    methods: {
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;

            if (sessionStorage.getItem('pageData')) {
                let pageData = JSON.parse(sessionStorage.getItem('pageData'));

                this.searchVal = pageData.searchVal;
                this.currentPage = pageData.pageNum;
                this.pagesize = pageData.pageSize;
                sessionStorage.removeItem('pageData');
                this.isPage = true;
            } else {
                this.isPage = true;
            }
            if (sessionStorage.getItem('searchCompanyName')) {
                this.searchVal.name =
                    sessionStorage.getItem('searchCompanyName');
            }

            this.getData();
        },
        handleChange(val) {
            console.log(val);
        },
        // 搜索获取列表
        getData() {
            setTimeout(() => {
                this.$refs.table.getData(
                    this.searchVal,
                    this.currentPage,
                    this.pagesize,
                    this.btnP
                );
            }, 0);
        },
        changeActive(i) {
            this.searchVal.auditStatus = i;
            this.pagesize = 20;
            this.pageNum = 1;
            this.getData();
        },
        pageData(i) {
            this.total = i;
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getData();
        },
        // 单选
        clickitem(e) {
            e === this.searchVal.auditStatus
                ? (this.searchVal.auditStatus = '')
                : (this.searchVal.auditStatus = e);
            this.getData();
        },

        // 发票详情
        onInvoiceDetails(row) {
            this.drawerInvoiceDetails = true;
            this.tableRow = row;
            this.getInvoice();
        },
        // 申报详情
        onDeclareDetails(row) {
            // this.drawerDeclareDetails = true;
            // this.tableRow = row;
            // this.getDeclare();
            this.$router.push({
                path: '/declareinfodetails',
                query: {
                    id: row.id,
                    isDetails: 1,
                },
            });
        },
        // 报销详情
        onReimbDetails(row) {
            this.drawerReimbDetails = true;
            this.tableRow = row;
            this.getReimb();
        },
        // 充值详情
        onRechargeDetails(row) {
            this.drawerRechargeDetails = true;
            this.tableRow = row;
            setTimeout(() => {
                this.$refs.rechargeDetail.getData(row);
            }, 0);
        },
        getInvoice() {
            let data = {
                param: {
                    id: this.tableRow.id,
                },
            };
            invoiceGet(data).then((res) => {
                if (res.code == 200) {
                    this.$refs.invoiceDetails.getData(res.data);
                }
            });
        },
        getDeclare() {
            let data = {
                param: {
                    id: this.tableRow.id,
                },
            };
            declareGet(data).then((res) => {
                if (res.code == 200) {
                    if (this.drawerDeclare) {
                        this.$refs.declareAudit.getData(res.data);
                    }
                    if (this.drawerDeclareDetails) {
                        this.$refs.declareDetail.getData(res.data);
                    }
                    if (this.drawerDeclarePay) {
                        this.$refs.declarePay.getData(res.data);
                    }
                }
            });
        },
        getReimb() {
            let data = {
                param: {
                    id: this.tableRow.id,
                },
            };
            expenseGet(data).then((res) => {
                if (res.code == 200) {
                    if (this.drawerReimb) {
                        this.$refs.reimbAudit.getData(res.data);
                    }
                    if (this.drawerReimbDetails) {
                        this.$refs.reimbDetail.getData(res.data);
                    }
                    if (this.drawerReimbPay) {
                        this.$refs.reimbPay.getData(res.data);
                    }
                }
            });
        },
        handleDrawer() {
            this.drawerInvoiceDetails = false;
            this.drawerDeclareDetails = false;
            this.drawerReimbDetails = false;
            this.drawerRechargeDetails = false;
            this.getData();
        },
    },
    destroyed() {
        sessionStorage.removeItem('searchCompanyName');
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.clue_box {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        p {
            display: inline-block;
            .el_radio_button_div {
                font-size: 14px;
                width: 70px;
            }
        }
        span {
            font-size: 12px;
            font-weight: 600;
            color: #000;
            margin: 0 16px;
        }
        .search-one {
            margin: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                button {
                    background: #2370eb;
                    border-radius: 2px;
                    border-color: #2370eb;
                }
                .el-range-editor--small.el-input__inner {
                    vertical-align: middle;
                    margin-left: 19px;
                }
            }
            .right {
                background: #2370eb;
                border-radius: 2px;
                border-color: #2370eb;
                margin-right: 16px;
            }
        }
    }

    .table_title {
        background: #fff;
        padding: 16px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .title_left_btn {
            span {
                display: inline-block;
                padding: 4px 13px;
                border-radius: 20px;
                font-size: 13px;
                color: #666666;
                border: 1px solid #f0f0f0;
                cursor: pointer;
            }
            span + span {
                margin-left: 12px;
            }
            .active {
                background: #e9f2ff;
                color: #2370eb;
            }
        }
        p {
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            button {
                background: #fff;
                border-color: #f0f0f0;
                color: #666666;
            }
            .isColor {
                border-color: #fff;
                color: #2370eb;
                background: #e9f2ff;
            }
        }
    }
    .table {
        flex: 1;
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .clue_btn {
        margin-top: 10px;
        position: absolute;
        top: -50px;
        right: 0;
        span {
            display: inline-block;
            padding: 10px 20px;
            margin-left: 15px;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            background-color: #fff;
        }
    }
}
.clue_assist_body {
    padding: 42px 74px 100px;
    .user {
        padding-bottom: 16px;
        display: flex;
        align-items: center;
        .left-spot {
            display: inline-block;
            width: 8px;
            height: 8px;
            background: #2370eb;
            border-radius: 50%;
            margin-right: 20px;
            margin-left: -22px;
        }
    }
    .user:last-child {
        .left-spot {
            display: inline-block;
            width: 7px;
            height: 7px;
            background: #fff;
            border: 1px solid #2370eb;
            border-radius: 50%;
            margin-right: 20px;
            margin-left: -22px;
        }
    }
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    background: #fff;
    justify-content: space-between;
    button {
        width: 72px;
    }
}

.dialog_info {
    width: 80%;
    margin: 25px auto 150px;
}
/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
}
/deep/ .el-textarea__inner {
    resize: none;
}
.search-two {
    margin-bottom: 10px;
}
</style>
